import GlobalVariables from "@/globalVariables.js";
import Vue from "vue";
Vue.use(GlobalVariables);

const DashboardLayout = () => import("../layout/DashboardLayout.vue");
const AuthLayout = () => import("@/pages/Dashboard/Pages/AuthLayout.vue");

// Dashboard pages
const Dashboard = () => import("../pages/Dashboard.vue");

// Auth pages
const Login = () => import("@/pages/Dashboard/Pages/Login.vue");
const RestoreAccount = () =>
  import("@/pages/Dashboard/Pages/RestoreAccount.vue");

// Dedicated Server pages
const DedicatedServerList = () => import("../pages/Server/DedicatedList.vue");

// Cloud Server pages
const CreateCloudUser = () =>
  import("@/pages/Dashboard/Server/CreateCloud.vue");
const CloudServerList = () => import("../pages/Server/CloudList.vue");
const CloudStatistic = () => import("../pages/Server/CloudStatistic.vue");
const ChangeSettings = () => import("../pages/Server/ChangeSettings.vue");
const NoVnc = () => import("@/pages/Dashboard/Server/NoVnc.vue");

// Firewall pages
const FirewallRules = () => import("@/pages/Dashboard/Pages/Rules.vue");

// Racks pages
const RacksList = () => import("../pages/Server/RacksList.vue");

// Service list
const ServiceList = () => import("../pages/Billing/ServiceList.vue");
// Cloud agreements
const CloudAgreements = () => import("@/pages/CloudAgreementsDetail.vue");
// Invoice pages
const InvoiceList = () => import("../pages/Billing/InvoiceList.vue");

// IP pages
const IPAddressesServer = () => import("../pages/Server/IPAddresses.vue");

// Traffic pages
const ServerTraffic = () => import("../pages/Server/Traffic.vue");
const TrafficNotificationList = () =>
  import("../pages/Server/TrafficNotificationList.vue");

// Client pages
const Account = () => import("@/pages/Account.vue");

// Template pages
const TemplateList = () => import("../pages/Template/List.vue");

// ISO pages
const IsoList = () => import("../pages/Iso/List.vue");

// User pages
const ContactList = () => import("../pages/User/ContactList.vue");
const ResellerList = () => import("../pages/User/ResellerList.vue");
const AddEditContact = () => import("../pages/User/AddEditContact.vue");
const AddEditReseller = () => import("../pages/User/AddEditReseller.vue");
const ResellerOfferList = () => import("../pages/User/ResellerOfferList.vue");

// Order page
const Order = () => import("@/pages/Dashboard/Pages/Order.vue");

// Backups pages
const BackupList = () => import("@/pages/Dashboard/Backup/BackupServer.vue");
const SnapshotList = () => import("../pages/Server/SnapshotList.vue");

// Help page
const HelpPage = () => import("../pages/HelpPage.vue");

// Error page
const PageError = () => import("@/pages/Dashboard/Pages/Error.vue");
const PageNotFound = () => import("@/pages/Dashboard/Pages/PageNotFound.vue");

// Maintenance mode page
const Maintenance = () => import("@/pages/Dashboard/Pages/Maintenance.vue");

// API documentation
const ApiDocumentation = () => import("@/pages/Dashboard/Pages/ApiDoc.vue");

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "login",
      name: "login",
      component: Login,
    },
    {
      path: "maintenance",
      name: "maintenance",
      component: Maintenance,
    },
    {
      path: "restore-account/:token/:hash",
      name: "Restore Account",
      component: RestoreAccount,
    },
  ],
};

let orderPages = {
  path: "/order/",
  component: AuthLayout,
  name: "Order",
  children: [
    {
      path: "server-housing",
      name: "server-housing",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "half-rack",
      name: "half-rack",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "full-rack",
      name: "full-rack",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "rootserver-r440",
      name: "rootserver-r440",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "rootserver-r640",
      name: "rootserver-r640",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "managed-server",
      name: "managed-server",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "managed-r440",
      name: "managed-r440",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "managed-r640",
      name: "managed-r640",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "ha-server",
      name: "ha-server",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "ha-r440",
      name: "ha-r440",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "ha-r640",
      name: "ha-r640",
      meta: { blesta: true },
      component: Order,
    },
    {
      path: "cloud-server",
      name: "cloud-server",
      meta: { blesta: true },
      component: Order,
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "dashboard",
      name: "dashboard_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
      },
      component: Dashboard,
    },
    {
      path: "/doc",
      component: ApiDocumentation,
      name: "api_doc",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["isMainUserAccount", "allowApiAccess"],
      },
    },
    {
      path: "account",
      name: "profile_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.billing,
        ],
      },
      component: Account,
    },
    {
      path: "server/dedicated/list",
      name: "dedicated_server_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        access: ["hasDedicatedServer"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: DedicatedServerList,
    },
    {
      path: "server/cloud/add",
      name: "create_cloud_server_user",
      meta: {
        requiresAuth: true,
        role: Vue.prototype.$roles.user,
        access: ["hasCloudOptions"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: CreateCloudUser,
    },
    {
      path: "server/cloud/list",
      name: "cloud_server_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        access: ["showCloudMenu"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: CloudServerList,
    },
    {
      path: "server/:id/ip-ptr",
      name: "ip_addresses_of_server_detail",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: IPAddressesServer,
    },
    {
      path: "server/cloud/:id/firewall",
      name: "cloud_firewall_detail",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        access: ["hasCloudOptions", "allowFirewall"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: FirewallRules,
    },
    {
      path: "server/:id/traffic",
      name: "traffic_of_server_detail",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: ServerTraffic,
    },
    {
      path: "server/:server_id/traffic/notification-rules",
      name: "traffic_notification_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
        access: ["allowTrafficNotification"],
      },
      component: TrafficNotificationList,
    },
    {
      path: "cloud/templates",
      name: "template_cloud_list",
      meta: {
        requiresAuth: true,
        role: Vue.prototype.$roles.user,
        access: ["showCloudMenu", "hasCloudOptions"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: TemplateList,
    },
    {
      path: "cloud/iso/list",
      name: "iso_cloud_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["showCloudMenu", "showIsoManager"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: IsoList,
    },
    {
      path: "cloud/statistic",
      name: "cloud_statistic_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
        access: ["showCloudMenu", "hasCloudOptions"],
      },
      component: CloudStatistic,
    },
    {
      path: "server/cloud/no-vnc",
      name: "server_cloud_novnc",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        access: ["showCloudMenu", "hasCloudOptions"],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: NoVnc,
    },
    {
      path: "/server/cloud/:id/backups",
      name: "backup_list_of_cloud_detail",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: BackupList,
    },
    {
      path: "/server/cloud/:id/snapshots",
      name: "snapshot_list_of_cloud_detail",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: SnapshotList,
    },
    {
      path: "/server/cloud/:id/change-definition",
      name: "change_settings_of_cloud_server",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: ChangeSettings,
    },
    {
      path: "racks",
      name: "rack_list_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        additionalRole: [
          Vue.prototype.$userRoles.admin,
          Vue.prototype.$userRoles.tech,
        ],
      },
      component: RacksList,
    },
    {
      path: "/services",
      name: "service_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["isMainUserAccount"],
        blesta: true,
      },
      component: ServiceList,
    },
    {
      path: "/cloud/agreements",
      name: "cloud_agreements",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["isMainUserAccount"],
        blesta: true,
      },
      component: CloudAgreements,
    },
    {
      path: "/invoice/list",
      name: "invoice_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["isMainUserAccount"],
        blesta: true,
      },
      component: InvoiceList,
    },
    {
      path: "user/add",
      name: "add_new_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        additionalRole: false,
      },
      component: AddEditContact,
    },
    {
      path: "user/:id/edit",
      name: "edit_normal_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        additionalRole: false,
      },
      component: AddEditContact,
    },
    {
      path: "user/list",
      name: "user_normal_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        additionalRole: [Vue.prototype.$userRoles.admin],
      },
      component: ContactList,
    },
    {
      path: "reseller/add",
      name: "add_new_reseller_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [Vue.prototype.$userRoles.admin],
      },
      component: AddEditReseller,
    },
    {
      path: "reseller/:id/edit",
      name: "edit_reseller_user",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [Vue.prototype.$userRoles.admin],
      },
      component: AddEditReseller,
    },
    {
      path: "reseller/list",
      name: "user_reseller_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [Vue.prototype.$userRoles.admin],
      },
      component: ResellerList,
    },
    {
      path: "reseller/offer",
      name: "reseller_offer_list",
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [Vue.prototype.$userRoles.admin],
      },
      component: ResellerOfferList,
    },
    {
      path: "help",
      name: "user_help",
      meta: {
        requiresAuth: true,
        access: ["hasHelpPage"],
        role: [Vue.prototype.$roles.user, Vue.prototype.$roles.reselleruser],
      },
      component: HelpPage,
    },
  ],
};

const routes = [
  { path: "/", redirect: { name: "login" } },
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "error",
        name: "user_page_error",
        component: PageError,
      },
    ],
  },
  authPages,
  orderPages,
  userPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "*",
        name: "user_page_not_found",
        meta: { showErrorBg: true, requiresAuth: true },
        component: PageNotFound,
      },
    ],
  },
];

export default routes;
