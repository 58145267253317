import i18n from "@/i18n";
import DashboardPlugin from "@/material-dashboard";
import axios from "axios";
import Vue from "vue";
import VueCookie from "vue-cookie";
import Vuex from "vuex";
import { sync } from "vuex-router-sync";
import routes from "./route";
import store from "./store";

// Plugins
import { VueSpinners } from "@saeris/vue-spinners";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/locale/en";
import App from "./App.vue";

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Vuex);
Vue.use(VueCookie);
Vue.use(VueSpinners);
Vue.use(DatePicker);

import "./plugins";

sync(store, routes);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  store,
  axios,
  router: routes,
  render: (h) => h(App),
});
