import i18n from "@/i18n";
import swal from "sweetalert2";
import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";

Vue.use(Router);

import routes from "./routes";

const router = new Router({
  mode: "history",
  routes,
  linkActiveClass: "nav-item active",
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["auth/isAdmin"] && store.getters["auth/getAccessToken"]) {
    window.location = "/admin/dashboard";

    return false;
  }

  // parse token if exists
  if (!store.getters["auth/hasUserId"] && store.getters["auth/getAccessToken"]) {
    await store.dispatch("auth/parseAccessToken", {
      token: store.getters["auth/getAccessToken"],
    });
  }

  if (
    to.name === "login" &&
    store.getters["auth/hasUserId"] &&
    store.getters["auth/getAccessToken"]
  ) {
    router.push({ name: "dashboard_user" });
  }

  // redirect to login if unauthorized
  if (to.meta.requiresAuth && !store.getters["auth/hasUserId"]) {
    return next("/login");
  }

  // redirect to login from order page if authorized
  if (to.name === "order" && store.getters["auth/hasUserId"]) {
    return next("/login");
  }

  if (to.meta.blesta !== undefined && !Vue.prototype.$useBlesta) {
    return next({ name: "dashboard_user" });
  }

  // redirect to dashboard if does not permission
  if (to.meta.role !== undefined) {
    let accessRole = store.getters["auth/hasRole"](to.meta.role);
    let accessToPage = true;
    let accessToAdditionalRole = true;

    if (Array.isArray(to.meta.role)) {
      accessRole = to.meta.role.some((role) => {
        if (store.getters["auth/hasRole"](role)) {
          return true;
        }

        return false;
      });
    }

    if (to.meta.access !== undefined) {
      accessToPage = to.meta.access.every(
        (access) => store.getters[`auth/${access}`]
      );
    }

    if (to.meta.additionalRole !== undefined) {
      let hasAdditionalUserRole = store.getters["auth/hasAdditionalUserRole"];

      if (to.meta.additionalRole === false) {
        accessToAdditionalRole = !hasAdditionalUserRole;
      }

      if (to.meta.additionalRole !== false && hasAdditionalUserRole) {
        accessToAdditionalRole = to.meta.additionalRole.some((role) =>
          store.getters["auth/hasRole"](role)
        );
      }
    }

    if (accessRole && accessToPage && accessToAdditionalRole) {
      return next();
    }

    swal.fire({
      text: i18n.t("error.access_denied"),
      icon: "error",
      customClass: {
        confirmButton: "md-button md-success",
      },
      buttonsStyling: false,
    });

    if (!store.getters["auth/isAdmin"]) {
      return next({ name: "dashboard_user" });
    }

    window.location = "/admin/dashboard";

    return next(false);
  }

  next();
});

export default router;
