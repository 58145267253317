import axios from "axios";

export const state = {
  isoList: [],
};

export const getters = {
  getIsoList: (state) => state.isoList,
};

export const mutations = {
  /**
   * Set iso list
   * @param {*} state
   * @param {*} isoList
   */
  setIsoList(state, { isoList }) {
    state.isoList = isoList;
  },
};

export const actions = {
  /**
   * Set iso list
   * @param {*} commit
   * @param {*} isoList
   */
  setIsoList({ commit }, { isoList }) {
    commit("setIsoList", { isoList });
  },

  /**
   * Fetch iso list
   * @param {*} commit
   */
  async fetchIsoList({ commit }) {
    try {
      const { data } = await axios.get("/cloud/iso/list");

      if (data.hasOwnProperty("isoList")) {
        commit("setIsoList", data);

        return true;
      }
    } catch ({ response }) {
      return response;
    }
  },
};
