import axios from "axios";
import store from "../store";

// Request interceptor
axios.interceptors.request.use((request) => {
  const accessToken = store.getters["auth/getAccessToken"];

  request.baseURL = "/api";
  request.headers.common["Content-Type"] = "application/json";

  if (accessToken) {
    request.headers.common["Authorization"] = `Bearer ${accessToken}`;
    request.headers.common["X-Refresh-Token"] = store.getters["auth/getRefreshToken"];
  }

  const locale = store.getters["lang/getLang"];

  if (locale) {
    request.headers.common["Accept-Language"] = locale;
  }

  return request;
});
